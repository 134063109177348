<template>
  <div class="billingSnap">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Billing Information for Snap</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <company-select @chosen="setCompany" v-model="companySelected" :colWidth="5"></company-select>
              <date-picker @clicked="setDateRange" v-model="datesPicked" :colWidth="5"></date-picker>
              <v-col cols="2">
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="billingDateRange()"
                >Run Query</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <div class="billingTable" v-if="data.length > 0">
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="mb-2 mt-2"
          @click="exportToExcel('xlsx')"
          type="button"
        >Excel</v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          small
          color="primary"
          class="ml-2 mb-2 mt-2"
          @click="exportToExcel('csv')"
          type="button"
        >CSV</v-btn>
        <v-card>
          <v-card-title>
            Billing Data
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="5"
            :search="search"
            id="data-table"
          >
            <template v-slot:item.chargeDate="{ item }">{{item.chargeDate.split(' ')[0]}}</template>
            <template v-slot:item.totalCharge="{ item }">
              <v-chip dark>{{ renderCurrency(item.totalCharge) }}</v-chip>
            </template>
          </v-data-table>
        </v-card>
        <v-snackbar v-model="snackbar" :timeout="5000">
          {{ text }}
          <v-btn color="blue" text @click="snackbar = false"></v-btn>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>
<script>
import BillingSnap from "@/services/BillingSnap.js";
import * as XLSX from 'xlsx'
import CompanySelect from "../components/forms/CompanySelect";
import DatePicker from "../components/forms/DatePicker";

export default {
  components: {
    CompanySelect,
    DatePicker
  },
  data() {
    return {
      // Snackbar
      snackbar: false,
      text: "",
      // Company
      companySelected: {},
      datesPicked: [],
      // Search
      search: "",
      // Button Loading
      loading: false,
      // Data
      data: [],
      // Form
      valid: false,
      // Query
      query: {
        companyCode: null,
        companyId: null,
        dateShippedFrom: "",
        dateShippedTo: ""
      },
      // Table
      headers: [
        { text: "Client", value: "client" },
        { text: "Charge Date", value: "chargeDate" },
        { text: "Charge Type", value: "chargeType" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "clientReference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billableUnits" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "totalCharge" },
        { text: "Charge Code", value: "chargeCode" }
      ]
    };
  },
  methods: {
    setCompany(company) {
      this.companySelected = company;
      this.query.companyCode = this.companySelected.code;
      this.query.companyId = this.companySelected.id;
    },
    setDateRange(dates) {
      this.datesPicked = dates;
      this.query.dateShippedFrom = this.datesPicked[0];
      this.query.dateShippedTo = this.datesPicked[1];
      this.query.week_commencing = null;
    },
    exportToExcel(type, fn, dl) {
      var elt = document.getElementById("data-table");
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
              this.query.companyCode +
                "SnapBilling_" +
                this.query.dateShippedFrom +
                "_" +
                this.query.dateShippedTo +
                "." +
                (type || "xlsx")
          );
    },
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    async getData() {
      this.loading = true;
      // Use the BillingSnap to call the getBillingData() method
      BillingSnap.getBillingData()
        .then(
          (data => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = "Successfully Loaded Data";
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    async billingDateRange() {
      this.loading = true;
      // Use the BillingSnap to call the getBillingDataDaterange(dateShippedFrom, dateShippedTo) method
      let companyInfo = {
        name: this.companySelected.name,
        companyId: this.companySelected.id
      }
      BillingSnap.getBillingDataDaterange(
        companyInfo,
        this.query.dateShippedFrom,
        this.query.dateShippedTo
      )
        .then(
          (data => {
            this.$set(this, "data", data);
            this.loading = false;
            this.snackbar = true;
            this.text = `Successfully Loaded Data between ${this.query.dateShippedFrom} and ${this.query.dateShippedTo}`;
          }).bind(this)
        )
        .catch(error => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.org-description {
  margin-top: 50px;
}
label {
  display: flex;
  align-items: center;
}
.button-end {
  align-self: flex-end;
}
</style>